<template>
  <Layout>
    <div class="main-product-detail">
      <div class="base-width">
        <!-- 搜索 -->
        <Search :shopCartNum="`${cartCount}+`" type shopCart />
        <!-- 路径以及店铺信息 -->
        <div class="md-pd-1 flex-middle">
          <!-- 面包屑 -->
          <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
          <div class="rg flex-middle">
            <!-- 店铺 -->
            <router-link class="store s16 color-2 flex-middle" :to="`/shop/seller?suppliers_id=${goodsObj.suppliers_id}`">
              <img :src="goodsObj.logo">
              <span>{{ goodsObj.shop_name }}</span>
            </router-link>
            <!-- 关注 -->
            <BaseCollect :value="Number(goodsObj.is_collect_shop)" @click.native="collectShop">关注店铺</BaseCollect>
            <!-- 联系客服 -->
            <div class="kefu pointer s14 color-blue bg-blue-light font-light flex-middle" @click="visible = true">
              <img src="@/assets/images/icon-kefu2.png" alt="">
              <span>联系客服</span>
            </div>
          </div>
        </div>
        <!-- 商品信息 -->
        <div class="md-pd-2 c-card flex-top" v-ani.fade-up>
          <!-- 相册  -->
          <ProductAlbum :mockList="goodsObj.goods_images"></ProductAlbum>
          <!-- 商品信息 -->
          <div class="prod-msg">
            <div class="prod-tit flex-middle">
              <div class="tit s22 color-2 font-bold">{{ goodsObj.goods_name }}</div>
              <BaseButton v-if="goodsObj.sell_mode" style-type='light' size="mini" :hover='false'>{{ goodsObj.sell_mode }}
              </BaseButton>
              <BaseCollect :value="Number(goodsObj.is_collect)" @click.native="collectGoods">收藏
              </BaseCollect>
            </div>
            <div class="prod-param s16 color-8">
              <div class="item flex-middle">
                <div class="price">
                  <span class="t1">价格：</span>
                  <div class="font-medium color-red inline-block">
                    <span class="s30">¥{{ goodsObj.shop_price | priceFormat }}</span>
                    <span class="s26">/{{ goodsObj.unit }}</span>
                  </div>
                </div>
                <BaseButton v-if="goodsObj.material_standard" style-type='border' size="mini" :hover='false'
                  class="label-1">
                  {{ goodsObj.material_standard }}
                </BaseButton>
              </div>
              <div class="item">
                <span class="t1">起批数量：</span>
                <span class="color-2">{{ goodsObj.limit_num }} {{ goodsObj.unit }} </span>
              </div>
              <div class="item">
                <span class="t1">品牌：</span>
                <span class="color-2">{{ goodsObj.brand_name || '无' }} </span>
              </div>
              <div class="item">
                <span class="t1">产品编号：</span>
                <span class="color-2">{{ goodsObj.goods_sn }}</span>
              </div>
              <div class="item">
                <span class="t1">发货地：</span>
                <span class="color-2">{{ goodsObj.address }}</span>
                <span class="color-9 split-1">|</span>
                <span class="color-9">运费请咨询客服</span>
              </div>
              <!-- <div class="item flex-middle">
                <span class="t1">收货地：</span>
                <BaseSelect placeholder="请选择收获地址" :options="addressList" ipt-class='address' v-model="address">
                </BaseSelect>
                <div class="btn-add pointer s14 color-9 flex-middle" @click="dialog_address = true">
                  <img src="@/assets/images/icon-114.png" alt="">
                  <span>新增地址</span>
                </div>
              </div> -->
              <div class="item">
                <span class="t1">备注：</span>
                <span class="color-2">{{ goodsObj.diy_remark }}</span>
              </div>
              <div class="item flex-middle">
                <span class="t1">服务：</span>
                <div class="flex-middle color-blue">
                  <span>账期服务</span>
                  <span class="split-2">|</span>
                  <span>次日送达(珠三角)</span>
                  <span class="split-2">|</span>
                  <span>货拉拉配送</span>
                  <div class="btn-service flex-middle">
                    <div class="btn-service_hd pointer">
                      <span>安心购</span>
                      <img src="@/assets/images/icon-113.png" alt="" class="icon">
                    </div>
                    <div class="btn-service_bd">
                      <div class="cnt">
                        <div class="list c-scroll">
                          <div class="li flex-middle" v-for="(item, index) in serviceList" :key="index">
                            <img :src="item.img" alt="" class="img">
                            <div class="text">
                              <div class="tit s16 color-2">{{ item.tit }}</div>
                              <div class="desc s14 color-9">{{ item.desc }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 规格选择 -->
        <div class="md-pd-3">
          <div class="c-title-2 s22">规格选择</div>
          <div class="table">
            <div class="thead s16 color-2 font-medium flex-middle">
              <div class="td">规格</div>
              <div class="td">库存</div>
              <div class="td">数量</div>
              <div class="td" style="width: 2rem;">价格</div>
            </div>
            <div class="tbody s16 color-2">
              <!-- v-for="(item, index) in specs" :key="index" -->
              <div class="list">
                <div class="li flex-middle" v-for="goods in goodsObj.goods_spec_item" :key="goods.id"
                  :class="{ on: goods.check }" @click="goods.check = !goods.check">
                  <div class="td">
                    <div class="flex-middle">
                      <img :src="goods.original_img" class="cover">
                      <div class="s16 t1">
                        <span class="color-6">{{ goods.key_name }}</span>
                        <!-- <span class="color-2">{{ goods.text }}</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="td">{{ goods.store_count }}</div>
                  <div class="td">
                    <BaseProductNumber :all="goods.store_count" v-model="goods.goodsNum"></BaseProductNumber>
                  </div>
                  <div class="td" style="width: 2rem;">￥{{ goods.price || 0 }}</div>
                </div>
              </div>
            </div>
            <div class="tfoot flex-middle">
              <div class="lf s16 color-2 font-medium">已选：{{ choosedNumber }}种商品</div>
              <div class="rg flex-middle">
                <div class="price font-medium">
                  <span class="s16 color-2">共计：</span>
                  <span class="s26 color-red">¥{{ priceAll | priceFormat }}</span>
                </div>
                <BaseButton class="font-bold" color="red" size='large' style-type='border' @click="cartClick">
                  加入购物车
                </BaseButton>
                <BaseButton class="font-bold" color="red" size='large' @click="placeOrder">下单
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
        <!-- 本地热推 -->
        <ProductRecommend v-if="hotArr.length > 0" :mockList="hotArr" title="本店热推" />
        <!--  -->
        <div class="md-pd-4 flex">
          <div class="lf">
            <!-- 店铺信息 -->
            <ProductSellerCard :shop_logo="goodsObj.logo" :shop_name="goodsObj.shop_name"
              :suppliers_id="String(goodsObj.suppliers_id)" :hatArr="goodsObj.level.hat" :starsArr="goodsObj.level.stars"
              :is_collect_shop="Number(goodsObj.is_collect_shop)" v-ani.fade-up @kefu='visible = true'
              @collectFn="collectShop" />
            <!-- 精选商品 -->
            <ProductSelected2 :listArr="selectArr" />
          </div>
          <div class="rg flex-1" v-ani.fade-up>
            <div class="tab s22 color-4 font-medium">
              <div class="tab-item" :class="{ on: currentTab == 1 }" @click="currentTab = 1">
                商品介绍
              </div>
              <div class="tab-item" :class="{ on: currentTab == 2 }" @click="currentTab = 2">
                商品评价
              </div>
            </div>
            <!-- 详情 -->
            <div class="md-pd-detail" v-show="currentTab == 1">
              <div class="editor_box">
                <div v-html="goodsObj.goods_content"></div>
              </div>
            </div>
            <!-- 评价 -->
            <ProductEvaluate v-show="currentTab == 2" :commentObj="commentObj" @change="evalSearch" :key="commentKey" />
          </div>
        </div>
      </div>
    </div>
    <!-- 客服弹窗 -->
    <BaseDialog :visible.sync='visible' width="16rem" class="kefu-dialog" :cancel='false' :confirm='false'>
      <div class="hd flex-middle" slot="title">
        <img src="@/assets/images/avatar.png" alt="" class="avatar">
        <div class="name s18 font-medium color-2">李四材料供应商</div>
      </div>
      <Kefu />
    </BaseDialog>
    <!-- 弹窗-收货人信息 -->
    <DialogAddress v-if="dialog_address" @close="dialog_address = false" @update="updateAddress" />
    <!-- 加购物车成功 -->
    <BaseDialog :visible.sync='dialog_cart' title="信息提示" :cancel='false' class="dialog" @confirm='handleConfirm'>
      <div class="dialog-text text-center">
        <img src="@/assets/images/icon-success.png" class="icon">
        <div class="t1 s26 color-2 font-medium">成功加入购物车</div>
        <div class="t2 s16 color-4">请前往购物车下单</div>
      </div>
    </BaseDialog>
  </Layout>
</template>

<script>
import {
  GoodsInfoApi,
  GoodsCommentListApi,
  IndexHotGoodsApi,
  SelectedProductsApi,
  CartAddApi,
  CartCountApi,
  CollectSuppliersApi,
  CollectGoodsApi
} from "@/request/api/shop";

import { CartBuyNowApi } from "@/request/api/order";
export default {
  created() {
    this.goods_id = this.$route.query.goods_id;
    GoodsInfoApi({ goods_id: this.goods_id }).then(({ status, data }) => {
      if (status == 200) {
        data.goods_spec_item.map(item => {
          item.check = false;
          item.goodsNum = 1;
        })
        this.goodsObj = data;
        IndexHotGoodsApi({ suppliers_id: this.goodsObj.suppliers_id, p: 1, page_num: 6 }).then(({ status, data }) => {
          if (status == 200) {
            this.hotArr = data.list;
          }
        })
      }
    })
    // 购物车数量
    CartCountApi().then(({ status, data }) => {
      if (status == 200) {
        this.cartCount = data.count;
      }
    })
    this.commentData.goods_id = this.$route.query.goods_id;
    this.commentFn();
    this.selectGoodsFn();
  },
  data() {
    return {
      goods_id: "",
      goodsObj: {
        goods_spec_item: [],
        level: {}
      },
      commentData: {
        goods_id: "",
        p: 1,
        page_num: 10,
        type: 0,
      },
      commentObj: {
        list: []
      },
      commentKey: 0,
      hotArr: [],
      selectArr: [],
      cartCount: 0,


      dialog_address: false,
      visible: false,
      routes: [
        { name: '首页', link: '/' },
        { name: '集采商城', link: '/shop' },
        { name: '商品详情', link: '' }
      ],
      follow: false,
      follow_goods: false,
      addressList: [
        { value: 1, name: '广东省广州市区天河区，大街5003号' },
        { value: 2, name: '广东省广州市区天河区，大街5003号' },
      ],
      address: '',
      currentTab: 1,
      serviceList: [
        {
          img: require('@/assets/images/icon-116.png'),
          tit: '账期服务',
          desc: '平台联合各大供应链金融机构对供应商在一定的账期内采用保理担保支付。'
        },
        {
          img: require('@/assets/images/icon-117.png'),
          tit: '次日送达',
          desc: '线下拥有多点配送中转站点，所在区域全覆盖，同城2小时送达，珠三角目前可实现当日送达。'
        },
        {
          img: require('@/assets/images/icon-118.png'),
          tit: '货拉拉配送',
          desc: '有专门配送团队，所在区域全覆盖，同城2小时送达，珠三角目前可实现当日送达。'
        },
      ],
      dialog_cart: false
    };
  },
  computed: {
    choosedNumber() {
      let res = 0
      this.goodsObj.goods_spec_item.forEach(el => {
        if (el.check) {
          res += 1
        }
      })
      return res
    },
    priceAll() {
      let num = 0
      this.goodsObj.goods_spec_item.forEach(el => {
        if (el.check) {
          num += el.goodsNum * el.price
        }
      })
      return num;
    }
  },
  methods: {
    // 关注店铺
    collectShop() {
      CollectSuppliersApi({ suppliers_id: this.goodsObj.suppliers_id }).then(({ status }) => {
        if (status == 200) {
          this.$message.success('操作成功');
          this.goodsObj.is_collect_shop = !this.goodsObj.is_collect_shop;
        }
      })
    },
    // 收藏商品
    collectGoods() {
      CollectGoodsApi({ goods_id: this.goodsObj.goods_id }).then(({ status }) => {
        if (status == 200) {
          this.$message.success('操作成功');
          this.goodsObj.is_collect = !this.goodsObj.is_collect;
        }
      })

    },
    // 评价列表
    commentFn() {
      GoodsCommentListApi(this.commentData).then(({ status, data }) => {
        if (status == 200) {
          this.commentObj = data;
          this.commentKey++;
        }
      })
    },
    // 评价搜索
    evalSearch(val) {
      this.commentData.type = val;
      this.commentData.p = 1;
      this.commentFn();
    },
    // 精选商品
    selectGoodsFn() {
      SelectedProductsApi({ p: 1, page_num: 2 }).then(({ status, data }) => {
        if (status == 200) {
          this.selectArr = data.list;
        }
      })
    },
    // 加入购物车
    cartClick() {
      let numArr = [];
      let specArr = [];
      this.goodsObj.goods_spec_item.map(item => {
        if (item.check) {
          numArr.push(item.goodsNum);
          specArr.push(item.key);
        }
      })
      CartAddApi({
        goods_id: this.goods_id,
        goods_num: numArr.toString(),
        goods_spec: specArr.toString()
      }).then(({ status, data }) => {
        if (status == 200) {
          this.dialog_cart = true;

        }
      })
    },
    // 下单
    placeOrder() {
      let numArr = [];
      let specArr = [];
      this.goodsObj.goods_spec_item.map(item => {
        if (item.check) {
          numArr.push(item.goodsNum);
          specArr.push(item.key);
        }
      })
      if (numArr.length > 1) {
        this.$message.error('暂只支持单个规格下单');
        return;
      }
      CartBuyNowApi({
        goods_id: this.goods_id,
        goods_num: numArr.toString(),
        goods_spec: specArr.toString()
      }).then(({ status, data }) => {
        if (status == 200) {
          this.$router.push('/order-fill?fromType=2');
        }
      })
    },
    updateAddress() { },
    handleConfirm() {
      this.dialog_cart = false
      this.$router.push('/shop-cart')
    },
  }
};
</script>

<style lang="scss" scoped>
.main-product-detail {
  padding-bottom: .4rem;
}

.md-pd-1 {
  justify-content: space-between;

  .store {
    img {
      width: .3rem;
      height: .3rem;
      border-radius: 50%;
      background: $blue;
      margin-right: .05rem;
    }
  }

  .c-collect {
    margin-left: .3rem;
  }

  .kefu {
    height: .3rem;
    border-radius: .04rem;
    padding: 0 .13rem;
    margin-left: .3rem;

    img {
      width: .13rem;
      margin-right: .05rem;
    }
  }
}

.md-pd-2 {
  padding: .3rem;

  .prod-msg {
    flex: 1;
    padding: .56rem 1.46rem .5rem 1.14rem;

    .prod-tit {
      padding-bottom: .25rem;
      border-bottom: 1px solid #e6f2f8;

      .base-button {
        padding: 0 .11rem;
        margin-left: .1rem;
      }

      .c-collect {
        margin-left: auto;
      }
    }

    .prod-param {
      padding-top: .24rem;

      .item {
        margin-bottom: .115rem;
      }

      .t1 {
        line-height: .4rem;
      }

      .label-1 {
        margin-left: .2rem;
      }

      .split-1 {
        margin: 0 .15rem;
      }

      .split-2 {
        color: #cae1ec;
        margin: 0 .08rem;
      }

      ::v-deep .address {
        width: 4rem;
        line-height: .4rem;
        border: 1px solid #cae1ec;
        padding: 0 .13rem;
      }

      .btn-add {
        margin-left: .18rem;

        img {
          width: .14rem;
          margin-right: .04rem;
        }

        &:hover {
          color: $blue;
        }
      }

      .btn-service {
        position: relative;
        margin-left: .2rem;

        &:hover {
          .btn-service_bd {
            display: block;
          }
        }

        &_hd {
          text-decoration: underline;

          .icon {
            width: .14rem;
            margin-left: .03rem;
          }
        }

        &_bd {
          position: absolute;
          left: -.16rem;
          bottom: 100%;
          padding-bottom: .27rem;
          width: 4.23rem;
          display: none;

          .cnt {
            position: relative;
            background: #fff;
            border-radius: .04rem;
            padding: .26rem .2rem;
            filter: drop-shadow(0 0 .3rem rgba(219, 236, 255, 0.8));

            &:before {
              content: '';
              position: absolute;
              left: .45rem;
              bottom: -.2rem;
              border: .1rem solid transparent;
              border-top-color: #fff;
            }
          }

          .list {
            max-height: 3rem;
            overflow-y: auto;

            .li {
              margin-bottom: .22rem;

              &:last-of-type {
                margin-bottom: 0;
              }
            }

            .img {
              width: .7rem;
              margin-right: .15rem;
            }

            .text {
              flex: 1;
            }

            .desc {
              line-height: calc(24 / 14 * 1em);
              margin-top: .09rem;
            }
          }
        }
      }
    }
  }
}

.md-pd-3 {
  margin-top: .2rem;

  .table {
    .td {
      &:not(:first-child) {
        text-align: center;
      }

      &:first-child {
        padding-left: .3rem;
        flex: 1;
      }

      &:nth-child(2) {
        width: 1.89rem;
        margin-right: 2.21rem;
      }

      &:nth-child(3) {
        width: 3.22rem;
      }
    }
  }

  .thead {
    height: 0.7rem;
    background-color: #ffffff;
    border-radius: 0.04rem;
    margin-top: .2rem;
  }

  .tbody {
    .td {
      padding: .2rem 0;
    }

    .list {
      margin-top: .2rem;

      .li {
        border: 1px solid transparent;
        border-bottom-color: #eaf4fa;
        background: #fff;

        &.on {
          background: #f4f9fd;
          border-color: #dbecff;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 0.04rem;
            height: 100%;
            background: $blue;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border: .1rem solid transparent;
            border-left-color: $blue;
          }
        }
      }

      .cover {
        width: .75rem;
        border-radius: 0.04rem;
        margin-right: .19rem;
      }
    }
  }

  .tfoot {
    margin-top: .2rem;
    background: #fff;
    height: .9rem;
    justify-content: space-between;

    .lf {
      padding-left: .3rem;
    }

    .rg {
      .price {
        margin-right: .38rem;
      }

      padding-right: 0.1rem;

      .base-button {
        min-width: 1.4rem;
        margin-right: .2rem;
      }
    }
  }
}

.md-pd-4 {
  margin-top: 0.2rem;

  .lf {
    width: 2.5rem;
    margin-right: .2rem;
  }

  .rg {
    background: #fff;
  }

  .tab {
    border-bottom: 1px solid #e6f2f8;
    line-height: .8rem;
    display: flex;
    padding: 0 .3rem;

    &-item {
      margin-right: .3rem;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      &.on {
        color: $blue;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 2px;
          background: $blue;
        }
      }
    }
  }
}

.kefu-dialog {
  ::v-deep {
    .c-dialog_header {
      height: 1rem;
    }

    .c-dialog_body {
      max-height: calc(100vh - 1rem);
    }
  }

  .hd {
    .avatar {
      width: .6rem;
      height: .6rem;
      border-radius: 50%;
      margin-right: .2rem;
    }

  }
}

.dialog-text {
  margin: 0.38rem 0 .14rem;

  .icon {
    width: .4rem;
  }

  .t1 {
    margin-top: .09rem;
  }

  .t2 {
    line-height: calc(42 / 26 * 1em);
    margin-top: .06rem;
  }
}

.editor_box {
  padding: 0.2rem;

  ::v-deep img {
    max-width: 100%;
    display: block;
  }
}
</style>